.root {
  display: block;

  &.size4 {
    height: 4px;
  }

  &.size6 {
    height: 6px;
  }

  &.size8 {
    height: 8px;
  }

  &.size12 {
    height: 12px;
  }

  &.size16 {
    height: 16px;
  }

  &.size18 {
    height: 18px;
  }

  &.size20 {
    height: 20px;
  }

  &.size22 {
    height: 22px;
  }

  &.size24 {
    height: 24px;
  }

  &.size28 {
    height: 28px;
  }

  &.size32 {
    height: 32px;
  }

  &.size34 {
    height: 34px;
  }

  &.size36 {
    height: 36px;
  }

  &.size38 {
    height: 38px;
  }

  &.size45 {
    height: 45px;
  }

  &.size48 {
    height: 48px;
  }

  &.size64 {
    height: 64px;
  }

  &.size68 {
    height: 68px;
  }

  &.size70 {
    height: 70px;
  }

  &.size72 {
    height: 72px;
  }

  &.size96 {
    height: 96px;
  }

  &.sizeMta {
    margin-top: auto;
  }
}
