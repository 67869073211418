:root {
  --black: #25262e;
  --gray: #66676f;
  --brand: #e83755;

  --color-text: var(--black);

  --mobile: 375px;
  --tablet: 768px;
  --laptop: 1220px;
}

html,
body {
  background: var(--color-white);
}

body {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  color: var(--color-text);
  font-weight: 500;
  font-size: 15px;
  font-family: var(--fonts);
  line-height: 1.7;
}

a {
  color: var(--brand);
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-weight: 400;
  font-size: 68px;
  font-family: var(--fonts-second);
  line-height: 74px;
  text-transform: uppercase;
}

h2 {
  font-weight: 400;
  font-size: 56px;
  font-family: var(--fonts-second);
  line-height: 60px;
  text-transform: uppercase;
}

h3 {
  font-weight: 400;
  font-size: 42px;
  font-family: var(--fonts-second);
  line-height: 50px;
  text-transform: uppercase;
}

h4 {
  font-weight: 500;
  font-size: 28px;
  font-family: var(--fonts);
  line-height: 34px;
}

h5 {
  font-weight: 400;
  font-size: 34px;
  font-family: var(--fonts-second);
  line-height: 38px;
  text-transform: uppercase;
}

h6 {
  font-weight: 400;
  font-size: 24px;
  font-family: var(--fonts-second);
  line-height: 36px;
}

p {
  font-weight: 400;
  font-size: 24px;
  font-family: var(--fonts);
  line-height: 34px;
}

@media (max-width: 1219px) {
  h1 {
    font-size: 64px;
    line-height: 72px;
  }

  h2 {
    font-size: 52px;
    line-height: 58px;
  }

  h3 {
    font-size: 40px;
    line-height: 48px;
  }

  h4 {
    font-size: 25px;
    line-height: 30px;
  }

  h5 {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 44px;
    line-height: 50px;
  }

  h2 {
    font-size: 38px;
    line-height: 42px;
  }

  h3 {
    font-size: 30px;
    line-height: 35px;
  }

  h4 {
    font-size: 22px;
    line-height: 26px;
  }

  h5 {
    font-size: 28px;
    line-height: 32px;
  }

  h6 {
    font-size: 22px;
    line-height: 30px;
  }

  p {
    font-size: 22px;
    line-height: 32px;
  }
}

@media (max-width: 374px) {
  h1 {
    font-size: 42px;
    line-height: 48px;
  }

  h2 {
    font-size: 36px;
    line-height: 40px;
  }

  h3 {
    font-size: 28px;
    line-height: 32px;
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
  }

  h5 {
    font-size: 26px;
    line-height: 30px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
  }
}
