.root {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 16px;
  font-size: 16px;
  line-height: 1.5;
  background: var(--color-white);
  border: 1px solid var(--color-ground-100);
  border-radius: 4px;
  box-shadow: 0px 4px 68px rgba(0, 0, 0, 0.12);
  color: var(--color-black);
  box-sizing: border-box;
}

.icon {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
  line-height: 1.3;
}