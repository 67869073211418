.root {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dotWrap {
  position: relative;
  top: 2px;
  display: flex;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--app-text-color);
  border-radius: 2px;
  transition: var(--transition-default);

  .input:checked + & {
    background-color: var(--color-black);
  }
}

.dot {
  position: absolute;
  top: 1px;
  left: 4px;
  display: none;
  width: 5px;
  height: 8px;
  border-top: 2px solid var(--color-white);
  border-left: 2px solid var(--color-white);
  border-radius: 1px;
  transform: rotate(-135deg);

  .input:checked + span > & {
    display: block;
  }
}

.label {
  margin-left: 12px;
  color: var(--app-text-color);
}
