.root {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.dotWrap {
  position: relative;
  top: 2px;
  display: flex;
  box-sizing: border-box;
  width: 35px;
  padding: 2px;
  background-color: transparent;
  border: 2px solid var(--color-ground-700);
  border-radius: 50px;
  transition: var(--transition-default);

  .input:checked + & {
    background-color: var(--app-text-color);
    border-color: transparent;
  }

  .input[disabled] + & {
    opacity: 0.3;
  }
}

.dot {
  position: relative;
  width: 12px;
  height: 12px;
  background-color: var(--app-text-color);
  border-radius: 50%;
  transition: transform 0.3s;

  .input:checked + * > & {
    background-color: var(--app-bg-color);
    transform: translateX(15px);
  }

  .input[disabled] + * > & {
    opacity: 1;
  }
}

.label {
  margin-left: 12px;
  color: var(--app-text-color);
}
