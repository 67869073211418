.root {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  color: var(--app-text-color);
  text-decoration: none;
  background-color: var(--color-ground-100);
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition-default);
  mask-image: radial-gradient(--color-white, --color-white);

  &:hover {
    background-color: var(--color-ground-200);
  }
}
