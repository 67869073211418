.inner {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  padding-right: 8px;
}

.bannerWrap {
  position: absolute;
  top: -65px;
  right: calc(100% - 15px);
  box-sizing: border-box;
  padding: 20px;
}

.banner {
  position: relative;
  box-sizing: border-box;
  padding: 8px 12px 6px;
  white-space: nowrap;
  background-color: var(--color-white);
  border-radius: 10px;
  cursor: pointer;
  filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.12));

  & svg path {
    transition: all 0.3s;
  }
}

.bannerTitle {
  color: var(--color-black);
  font-size: 16px;
  /* stylelint-disable-next-line declaration-no-important */
  font-family: var(--fonts-second) !important;
  line-height: 22px;
}

.bannerSubtitle {
  display: flex;
  align-items: center;
  color: var(--color-ground-700);
  font-size: 16px;
  /* stylelint-disable-next-line declaration-no-important */
  font-family: var(--fonts-second) !important;
  line-height: 22px;
  transition: all 0.3s;

  .banner:hover & {
    color: var(--color-black);
  }

  .banner:hover & svg path {
    fill: var(--color-black);
  }
}

.bannerTail {
  position: absolute;
  top: 100%;
  right: 15px;
  display: flex;
}

.contentWrap {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  background-color: var(--color-white);
  border-radius: 50%;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  background-color: var(--color-white);
  border-radius: 50%;
  cursor: pointer;
  filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.2));

  &:hover svg path {
    transition: all 0.3s;
    fill: var(--color-ground-700);
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
