@font-face {
  font-weight: 400;
  font-family: 'PP Neue Machina';
  font-style: normal;
  src: url('chrome-extension://niipedbmjiopjpmjcpigiflabghcckeo/fonts/PPNeueMachina-Regular.woff2')
    format('woff2');
}

.root {
  position: fixed;
  top: 165px;
  right: -75px;
  z-index: 9999999;
  font-family: var(--fonts-second);
  transition: all 0.3s;

  &:hover {
    right: -35px;
  }
}

.arrowWrapper {
  position: absolute;
  z-index: 11;
  top: 181px;
  right: 54px;
  transition: all 0.3s;
}

.description {
  position: absolute;
  z-index: 11;
  top: 347px;
  right: 24px;
  max-width: 386px;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-white);
  font-feature-settings: 'liga' off;
}
