.root {
  color: var(--color-ground-200);
  background: var(--color-black);
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 50vh;
}

.spaceButton {
  min-width: auto;
  min-height: 22px;
  padding: 3px 8px 5px;
  color: var(--app-text-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  background-color: var(--color-ground-100);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: var(--transition-default);

  &:hover {
    background-color: var(--color-ground-200);
  }
}

.waitBlock {
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 180px);
  z-index: 5;
}

.activeBlock {
  position: absolute;
  top: calc(50% - 262px);
  left: calc(50% - 226px);
}

.title {
  margin: 0;
  color: var(--app-text-color);
  font-weight: 400;
  font-size: 40px;
  font-family: var(--fonts-second);
  line-height: 50px;
  text-align: center;
}

.animate {
  margin: 0;
  color: var(--app-text-color);
  font-weight: 400;
  font-size: 40px;
  font-family: var(--fonts-second);
  line-height: 50px;
  text-align: center;
  animation: opacity 2s infinite linear;
}

@keyframes opacity {
  60% {
    opacity: 0.8;
  }

  70% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

.animateActive {
  margin: 0;
  color: var(--app-text-color);
  font-weight: 400;
  font-size: 40px;
  font-family: var(--fonts-second);
  line-height: 50px;
  text-align: center;
  animation: opacityActive 4s infinite linear;
}

.breathContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 360px;
  border-radius: 50%;
}

.breathContainerActive {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 452px;
  height: 452px;
}

.displayNone {
  display: none;
}

.display {
  display: inline-block;
}

.breathRound {
  width: 360px;
  height: 360px;
  background: url('./assets/Smile.svg') center border-box no-repeat;
  background-size: cover;
  border-radius: 50%;
  animation: breath-wait 8s infinite linear;
  translate: 3s ease;
}

.breathRoundActive {
  width: 350px;
  height: 350px;
  background: url('./assets/SmileActive.svg') center border-box no-repeat;
  background-size: cover;
  animation: breath-active 16s infinite linear;
}

.breathRoundActiveWait {
  width: 350px;
  height: 350px;
  background: url('./assets/SmileActive.svg') center border-box no-repeat;
  background-size: cover;
}

.playButton {
  position: absolute;
  top: 152px;
  left: 152px;
  width: 56px;
  height: 56px;
  background-size: cover;

  & path {
    fill: var(--color-sunrise);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}

.submitDescription {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

@keyframes breath-wait {
  25% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(0.89);
  }

  55% {
    transform: scale(0.89);
  }

  75% {
    transform: scale(0.92);
  }

  95% {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}

@keyframes breath-active {
  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1.19);
  }

  75% {
    transform: scale(0.96);
  }

  to {
    transform: scale(1);
  }
}

.progressContainer {
  position: absolute;
  z-index: 10;
  width: 452px;
  height: 452px;
}

.progress {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  transform-origin: center center;
  animation: progress 16s linear;
  fill: none;
  stroke: var(--color-ground-100);
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 1381.6;
  stroke-dashoffset: 1381.6;
}

@keyframes progress {
  from {
    stroke-dashoffset: 1381.6;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.progressText {
  height: 50px;
}

.titleBlack {
  margin: 0;
  color: var(--color-ground-200);
  font-weight: 400;
  font-size: 40px;
  font-family: var(--fonts-second);
  line-height: 50px;
  text-align: center;
}

.breathContainerBlack::before {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 360px;
  background: #ffe600;
  border-radius: 50%;
  box-shadow: 0 0 110px 10px #ffe600;
  filter: blur(20px);
  animation: breath-wait-black 8s infinite linear;
  content: '';
}

.breathRoundBlack {
  position: absolute;
  top: 170px;
  left: calc(50% - 78px);
  width: 156px;
  height: 22px;
  background: url('./assets/eyes.svg') top border-box no-repeat;
  background-size: cover;
}

.breathRoundActiveBlack {
  width: 300px;
  height: 300px;
  background: #ffe600;
  border-radius: 50%;
  box-shadow: 0 0 110px 110px #ffe600;
  filter: blur(20px);
  animation: breath-active-black 16s infinite linear;
}

.breathRoundActiveWaitBlack {
  width: 300px;
  height: 300px;
  background: #ffe600;
  border-radius: 50%;
  box-shadow: 0 0 110px 110px #ffe600;
  filter: blur(20px);
}

.playButtonBlack {
  position: absolute;
  top: 226px;
  left: 152px;
  width: 56px;
  height: 56px;
  background-size: cover;
  opacity: 0.5;

  & path {
    fill: var(--color-black);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}

.blackButton {
  min-width: auto;
  min-height: 22px;
  padding: 3px 8px 5px;
  color: var(--color-white);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
  background: var(--color-ground-800);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: var(--transition-default);

  &:hover {
    background-color: var(--color-ground-200);
  }
}

@keyframes breath-wait-black {
  25% {
    box-shadow: 0 0 200px 100px #ffe600;
  }

  50% {
    box-shadow: 0 0 300px 200px #ffe600;
  }

  75% {
    box-shadow: 0 0 200px 100px #ffe600;
  }

  to {
    box-shadow: 0 0 110px 10px #ffe600;
  }
}

@keyframes breath-active-black {
  25% {
    box-shadow: 0 0 180px 180px #ffe600;
  }

  50% {
    box-shadow: 0 0 200px 200px #ffe600;
  }

  75% {
    box-shadow: 0 0 130px 130px #ffe600;
  }

  to {
    box-shadow: 0 0 110px 110px #ffe600;
  }
}

.progressBlack {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  transform-origin: center center;
  opacity: 0.3;
  filter: blur(2px);
  animation: progress 130s linear;
  fill: none;
  stroke: var(--color-white);
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 1381.6;
  stroke-dashoffset: 1381.6;
}

.progressTextBlack {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 452px;
  height: 452px;
}

.progressTextButtonActive {
  color: var(--color-black);
  font-weight: 400;
  font-size: 40px;
  font-family: var(--fonts-second);
  line-height: 50px;
  animation: opacityActive 4s infinite linear;
}

.progressTextButton {
  color: var(--color-black);
  font-weight: 400;
  font-size: 40px;
  font-family: var(--fonts-second);
  line-height: 50px;
  animation: opacity 2s infinite linear;
}

@keyframes opacityActive {
  90% {
    opacity: 0.8;
  }

  92% {
    opacity: 0.6;
  }

  94% {
    opacity: 0.4;
  }

  96% {
    opacity: 0.2;
  }

  98% {
    opacity: 0.1;
  }

  to {
    opacity: 0;
  }
}

.buttonWrap {
  margin-top: 96px;
  display: flex;
  justify-content: center;
}
