.root {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: fit-content;
  min-height: 50px;
  padding: 5px 24px;
  color: var(--app-bg-color);
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  background-color: var(--app-text-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: var(--transition-default);

  &:hover {
    opacity: 0.8;
  }

  &.appearance-ghost {
    min-width: auto;
    color: var(--app-text-color);
    font-size: 16px;
    line-height: 25px;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: var(--color-ground-800);
    }

    &:active {
      color: var(--color-ground-700);
    }

    &:focus {
      color: var(--app-text-color);
    }

    &:disabled {
      color: var(--color-ground-400);
    }
  }

  &.appearance-simple-invert {
    color: var(--app-text-color);
    background-color: transparent;
    border: 2px solid var(--app-text-color);
  }

  &.appearance-provide {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}

.content {
  display: inline-flex;
}

.iconLeft,
.iconRight {
  display: inline-flex;

  & path {
    fill: var(--app-bg-color);
  }

  .root.appearance-simple-invert & path {
    fill: var(--app-text-color);
  }
}

.iconLeft {
  margin-right: 10px;

  .root.appearance-provide & {
    margin-right: 34px;
  }
}

.iconRight {
  margin-left: 10px;
}
