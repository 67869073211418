@font-face {
  font-weight: 400;
  font-family: 'PP Neue Machina';
  font-style: normal;
  src: url('./fonts/PPNeueMachina-Regular/PPNeueMachina-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'TT Norms';
  font-style: normal;
  src: url('./fonts/TTNormsPro/TTNormsProRg/font.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: 'TT Norms';
  font-style: bold;
  src: url('./fonts/TTNormsPro/TTNormsProMd/font.woff2') format('woff2');
  font-display: swap;
}
